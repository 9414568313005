


const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api' : 'https://alraqeem.ly/api';

export default baseUrl;


export type levelsType = {
    beginner: string,
    middle: string,
    advanced: string
}

export const levels: levelsType = {
    beginner: "مبتدئ",
    middle: "متوسط",
    advanced: "متقدم"
}