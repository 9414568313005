import React from 'react'

import reocImg from 'src/assets/images/services/reoc.jpg';
import { Styled } from './Styled.styled';


// Review and Evaluation Of Companies
function REOC() {
    return (
        <Styled>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-7 content'>
                        <div className='sm-title'>
                            من خدماتنا /
                        </div>
                        <div className='title'>
                            <h4>
                                مراجعة وتقييم الشركات
                            </h4>
                            <div className='underline'>

                            </div>
                        </div>

                        <div>
                            <div className='text'>
                                <p>
                                    هي عمليات وإجراءات وأعمال يقوم بها طاقمنا المهني لإبداء الرأي حول البيانات المالية لعملائنا وفقاً لمعايير المراجعة المقبولة والمتعارف عليها، والمعايير الدولية، بالإضافة إلى ذلك فإن أعمال المراجعة مصممة لتقديم المشورة لأصحاب الأعمال، والمدراء بما يفيدهم في اتخاذ قراراتهم وتحقيق أهداف شركاتهم، جميع أعمال المراجعة نبدأها بفهم وتقييم طبيعة أعمال الشركة المراد مراجعتها. مما يساعدنا على تقديم اقتراحات بناءة لتطوير أعمال الشركة، وخططها الاستراتيجية، وأنظمة المعلومات، والرقابة الداخلية لديها، ومن ثم تطبيق إجراءات المراجعة التي تتماشى مع متطلبات الأنظمة والقوانين المحلية، ومعايير المراجعة الدولية.
                                    <br />
                                    وفيما يخص أنشطة الرقيم في هذا المجال فإن فريقنا يقوم بالآتي :

                                    <ol>
                                        <li>
                                            القيام بمراجعة عمليات الشركة وفقاً لمعايير المراجعة الدولية، ووفقاً لأحكام قانون المحاسبين والمراجعين القانونيين الليبي.
                                        </li>
                                        <li>
                                            القيام بعملية المراجعة على فترات ربع سنوية – نصف سنوية – سنوية – المراجعة الدورية
                                        </li>
                                        <li>
                                            إعداد التقرير الفني حول فحص التقارير والقوائم المالية للشركات والمؤسسات وإبداء الملاحظات والتوصيات
                                        </li>
                                        <li>
                                            إعداد تقارير فنية عن مدى فاعلية نظام الرقابة الداخلية المطبق في الشركة محل الفحص
                                        </li>
                                    </ol>
                                </p>

                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className='img-con'>
                            <img src={reocImg} />
                        </div>
                    </div>
                    <div className='col-12 content'>
                        <div className='text'>
                            <p>
                                حيث يقـوم فريق المراجعة بالشركة بتصميم نظم المراجعة الداخلية لجميع المؤسسات على اختلاف أنشطتها، وهي تهدف إلى ضبط المراقبة الداخلية حتى يمكن المحافظة على أموال الشركة وحماية حقوقها لدى الغير وذلك بوضع الإجراءات اللازمة للتحقق من صحة البيانات المحاسبية ومتابعة الوسائل والإجراءات التي يمكن بواسطتها تلافى وقوع أخطاء مقصودة أو غير مقصودة واكتشاف الخطأ فور حدوثه والعمل على تصحيحه.
                                <br />
                                <br />
                                ان الهدف الأساسي لفحص ومراجعه القوائم المالية هو:
                                <br />
                                ابداء رأى فني محايد عن صحة تعبير هذه القوائم عن المركز المالي ونتائج الاعمال وفقا للمبادئ المحاسبية الدولية وذلك من خلال عمل دراسة تحليله لنشاط العميل وفحص كافة المشاكل التي تواجه الشركة وتتبع أثرها، لتقديم الخبرة والنصيحة التعاون المستمر مع المسئولين بالمنشاة، وهذه الامور تتطلب :

                                <ul>
                                    <li>
                                        اعداد نظام محاسبي سليم ودوره مستنديه يمكن الاعتماد عليها.
                                    </li>
                                    <li>
                                        العناية بتخطيط عمليه المراجعة.
                                    </li>
                                    <li>
                                        وضع برنامج المراجعة الذي يتفق مع النظام المحاسبي المستخدم بعد تقييم نظم الرقابة الداخلية
                                    </li>
                                    <li>
                                        تحديد نقاط الضعف في النظام المتبع وتوجيه نظر الإدارة اليها
                                    </li>
                                    <li>
                                        استخدام الاساليب الحديثة في المراجعة
                                    </li>
                                    <li>
                                        التحقق من تنفيذ ما تتطلبه القوانين واسس وقواعد المحاسبة الدولية واتباع ارشادات المراجعة الدولية ومساعده الشركة في وضع الخرائط التنظيمية والتوصيف الوظيفي.
                                    </li>
                                    <li>
                                        اعداد نظم التقارير المالية.
                                    </li>
                                    <li>
                                        اعداد سياسات وإجراءات وضع الموازنات التقديرية.
                                    </li>
                                    <li>
                                        اعداد نظم التدفقات النقدية.
                                    </li>
                                    <li>
                                        اعداد لوائح تنظيم العمل داخل الشركة.
                                    </li>
                                    <li>
                                        تقييم المخاطر بكافة انواعها
                                    </li>
                                    <li>
                                        اصدار تقرير مراجع خارجي.
                                    </li>
                                </ul>
                            </p>

                            <p>
                                وغالبـا مـا يحـرص المسـاهمون والدائنون وكـذا الجهـات الحكوميـة والرقابيـة للحصـول علـى قوائـم ماليـة تـم مراجعتـها من مراجع خارجي طبقـاً لمعايـير المراجعـة الدوليـة وأنهـا متوافقـة مـع معاييـر المحاسـبة والتقاريـر الماليـة وذلـك للتأكـد مـن أن أنهـا تعكـس بعـدالة ووضـوح حقيقـة المركـز المالي للشركة أو المنظمة.
                                <br />
                                وتماشياً مع الفلسفة الخاصة بالتدقيق والمراجعة، لا ينصب اهتمام مكتبنا على عملية المراجعة فقط، بل يمتد هذا الاهتمام إلى كل ما شأنه المساعدة على استكشاف فرص تطوير ورفع كفاءة وربحية الأعمال وتسليط الضوء على مواطن الضعف والجوانب التي تتطلب تحسينات عاجلة والمخاطر المتوقعة حيث يتم الإبلاغ بصورة منتظمة من خلال تدوين الملاحظات والتوصيات الملائمة.
                                <br />
                                إن أسلوبنا في المراجعة والتدقيق يقوم على تخطيط و تنفيذ الفحوصات الدقيقة والفهم التام للأعمال وخصوصيات بيئة القطاع الاقتصادي الذى نعمل فيه.
                                <br />
                                تعرف المراجعة كما عرفتها جمعية المحاسبة الامريكية بأنها " عملية منظمة ومنهجية لجمع وتقييم الادلة والقرائن بشكل موضوعي التي تتعلق بنتائج الانشطة والاحداث الاقتصادية، وذلك لتحديد التوافق والتطابق بين هذه النتائج والمعايير المقررة وتبليغ الاطراف المعنية بنتائج التدقيق "
                                <br />
                                وتتم عملية المراجعة طبقا لمعايير المراجعة الدولية وطبقا للقوانين واللوائح والنظم الليبية في هذا الشأن للوصول إلى تأكيد معقول بان القوائم المالية لا تنطوي على أخطاء أو تحريف وتشمل اعمال المراجعة ما يلي:
                                <br />
                                إجراء مراجعة اختبارية للسجلات المحاسبية بالمنشاة ومستنداتها طبقا لإجراءات المراجعة المخططة في ضوء ظروف المنشاة.
                                <br />
                                فحص وتقييم المبادئ والسياسات المحاسبية المتبعة بالمنشأة والمخصصات التي تحددها إدارة المنشأة بطريق التقدير
                                <br />
                                تقييم عام لطريقة عرض القوائم المالية وما تتضمنه من إفصاحات وايضاحات.
                                <br />
                                عمل مراجعة دورية خلال العام تتضمن فحص وتقييم للنظم المحاسبية ونظم الرقابة الداخلية المطبقة بالمنشاة والقيام بجميع الاختبارات اللازمة للتحقق من سلامة تطبيق تلك النظم وكفاءتها وبذل المجهود المهني اللازم لاكتشاف الأخطاء والمخالفات الهامة التي قد يكون لها تأثيراً على القوائم المالية للمنشاة.
                                <br />
                                وفي حالة وجود ملاحظات هامة تسفر عنها المراجعة الدوريةـ يتم إرسالها في تقرير لإدارة المنشاة يتضمن هذه الملاحظات وتوصياتها لمعالجتها وذلك بعد مناقشتها مع إدارة المنشأة.
                                <br />
                                عمل مراجعة نهائية في نهاية العام تشمل ما يلي :

                                <ul>
                                    <li>
                                        فحص ومطابقة الحسابات الختامية على السجلات المحاسبية.
                                    </li>

                                    <li>
                                        التحقق من وجود الأصول التي تظهر بالميزانية وقيمتها والمبالغ الخاصة بالالتزامات والمخصصات للتحقق من أن الميزانية تعبر بوضوح عن حقيقة المركز المالي.
                                    </li>
                                    <li>

                                        فحص قائمة الدخل للتأكد من انها تظهر صحة نتيجة وتشغيل المنشأة.
                                    </li>

                                    <li>
                                        التأكد من أن الإفصاح والعرض بالإيضاحات المتممة لقوائم المالية تم وفقا للمبادئ المحاسبية المتعارف عليها وفى ظل القوانين واللوائح المعمول بها.
                                    </li>
                                    <li>
                                        اعداد تقرير المراجع الخارجي على القوائم المالية في نهاية السنة المالية. وعند الانتهاء من أعمال المراجعة النهائية إذا تبين وجود ملاحظات متعلقة بمراجعة القوائم المالية أو اية ملاحظات أخرى بخلاف ما ورد بالتقرير عن نتيجة المراجعة الدورية فسوف يتم إرسال تقرير يتضمن ملاحظاتنا عن أعمال المراجعة النهائية وذلك بعد مناقشته مع إدارة المنشاة.
                                    </li>
                                </ul>

                            </p>

                        </div>
                    </div>

                </div>
            </div>

        </Styled>
    )
}

export default REOC