import React from 'react'

import adsImg from 'src/assets/images/services/asd.jpg';
import { Styled } from './Styled.styled';

// Accounting and Systems Design
function ASD() {
    return (
        <Styled>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-7 content'>
                        <div className='sm-title'>
                            من خدماتنا /
                        </div>
                        <div className='title'>
                            <h4>
                                خدمات المحاسبة وتصميم النظم
                            </h4>
                            <div className='underline'>

                            </div>
                        </div>

                        <div>
                            <div className='sub-title'>
                                <h4>
                                    تحليل وتصميم وتطوير نظام المعلومات المحاسبية
                                </h4>
                            </div>
                            <div className='text'>
                                <p>
                                    تعتبر النظم المحاسبية هي الشغل الشاغل للمؤسسات المختلفة حيث إن نتائجها من المعلومات تعتبر بمثابة الأرضية لاتخاذ القرارات المالية والإدارية في المؤسسات المختلفة، ولكي تكون القرارات ذات فاعلية عالية فلا بد من وجود نظام معلومات محاسبي كفؤ وفعال.
                                </p>
                            </div>
                        </div>

                        <div>
                            <div className='sub-title'>
                                <h4>
                                    تسجيل وإعداد قيود اليومية
                                </h4>
                            </div>
                            <div className='text'>
                                <p>
                                    يوفر هذا القيد المحاسبي ضوابط مهمة لتطبيق مبدأ الإفصاح المحاسبي حيث ينص على ضرورة الإفصاح فقط عن البنود ذات الأهمية النسبية في القوائم وعدم اشتمال هذه القوائم على تفاصيل وبنود لا داعي للإفصاح عنها مما قد يسبب إرباكاً للمستخدم وتضليلاً له بل وقد يفقده القدرة على التمييز بين ما هو مهم وما هو أقل أهمية، هذا ويوصف البند على أنه مهم نسبياً في ضوء بعض الاعتبارات الكمية والنوعية، كقيمته النسبية ضمن مجموعة معينة ينتمي إليها، أو طبيعته كأن يكون بنداً غير عادي أو بنداً فرضته القوانين والتعليمات.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className='img-con'>
                            <img src={adsImg} />
                        </div>
                    </div>
                    <div className='col-12 content'>
                        <div>
                            <div className='sub-title'>
                                <h4>
                                    إعداد الحسابات الختامية وإصدار التقارير المالية
                                </h4>
                            </div>
                            <div className='text'>
                                <p>
                                    تعتبر التقارير المالية المنشورة من قبل الشركات المادة الأساسية للتحليل المالي، وهي مصدراً مهماً من مصادر المعلومات التي يلجأ إليه ويعتمد عليها متخذو القرارات والمستفيدون، وهي الإطار العام والأوسع الذي يضم القوائم المالية والمعلومات غير المالية التي لا يمكن الإفصاح عنها في القوائم
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className='sub-title'>
                                <h4>
                                    تصميم نظم التكاليف
                                </h4>
                            </div>
                            <div className='text'>
                                <p>
                                    والهدف منه جمع وتسجيل وعرض بيانات التكاليف لقياس التكلفة ودراسة عناصر التكلفة بناء على مقومات واسس علمية لتوفير البيانات الازمة لاتخاذ القرارات
                                    نهـدف أن نقـدم معلومـات ماليـة دقيقـة لعملائنا في الوقـت المناسـب لأن السـجلات الماليـة المنتظمـة تؤكـد أن ‏المنشـأة تعمـل بصـورة أكثـر كفاءة. ‏ تقـوم الشركة بتقديـم مجموعـة مـن الخـدمات المحاسـبية للشـركات والمنظمـات غيـر الهـادفة للربـح تتضمـن ‏إمسـاك الدفاتـر والسـجـلات، والخـدمات المرتبطـة بالأجـور والمرتبـات، إعـداد القوائـم الماليـة، وتصـميم ‏وتطـوير النظـم المحاسـبية. ‏
                                    تقـوم الشركة بتقديم خدماته الاستشارية المحاسبية والمالية لعملائه وعلى سبيل المثال مساعدة المنشآت في تطوير العمل المحاسبي لديها واحكام الرقابة الداخلية لها وذلك عن طريق تصميم الدورة المستندية المناسبة للمنشآت وتصميم نظم الرقابة الداخلية المناسبة لكل منشاة.
                                    نحن في شركة الرقيم المحاسبي نمتلك جميع المهارات والخبرات اللازمة لخدمتك بغض النظر عن حجم أعمالك، إننا نعمل معك جنباً إلى جنب، كشريك استراتيجي لاستكشاف الفرص التي تمكنك من تنمية أعمالك وتحقيق الارباح بالإضافة إلى الاستخدام الأمثل لمواردك بصورة أكثر كفاءة وفاعلية.
                                    بإمكاننا أن نوفر لك مجموعة متكاملة من الخدمات ابتداءً من مسك الدفاتر المحاسبية إلى التخطيط المالي لأعمالك وتصميم وتطوير نظم المعلومات الإحصائية والإدارية بهدف تمكينك من التحكم بصورة أفضل في عملية اتخاذ القرار وترشيد الإنفاق.
                                    نود تذكيركم كذلك أنه بإمكانكم دائماً الاعتماد علينا في توفير أفضل الخبرات المحاسبية والإدارية وعلى سبيل المثال أعمال الرقابة الداخلية، التي من شأنها إحداث إضافات إيجابية لأعمالكم.

                                </p>
                            </div>
                        </div>
                        <div>
                            <div className='sub-title'>
                                <h4>
                                    تقديم الاقرارات الضريبية
                                </h4>
                            </div>
                            <div className='text'>
                                <p>
                                    تقدم الشركة  الاستشارات الضريبيـة لعملائه مـن الأفـراد، والشـركات وكـذا المنظمـات غيـر الهـادفة للربـح ‏مـدار السـنة، في ضـوء أحكـام قانوـن الضرائـب الليبي. وتتضمـن الخـدمات المهنيـة المقدمـة إعـداد كافة أنواع الإقرارات الضريبية وتجهيز مستندات الفحص الضريبي. كما نساعد في حل المنازعات الضريبية التي قد تظهر أثناء الفحص الضريبي، بما يحقق الحفاظ على اموال الدولة ولا يتعارض مع مصلحة الممول
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Styled>
    )
}

export default ASD