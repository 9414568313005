import { styled } from 'styled-components';


export const LgStyled = styled.section`
    margin-top:2rem;

    .card{
        height: 352px;
        -webkit-box-shadow: 0px 0px 16px -3px rgba(0,51,153,0.27);
        -moz-box-shadow: 0px 0px 16px -3px rgba(0,51,153,0.27);
        box-shadow: 0px 0px 16px -3px rgba(0,51,153,0.27);
        gap: 2.5rem;
        margin-bottom: 7rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        background-color: #fff;

        .img-con{
            width: 40%;
            height: 350px;
            

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center top;
                filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.125));
                border-radius: 0px 30px;
                transform: scale(1.08);
            }
        }
        
        .content{
            width: 60%;
            align-items: center;
            padding: 1.6rem 2rem 1.3rem;

            h4{
                margin-bottom: 1rem;
                font-family: var(--ftl);
                font-weight: bold;
                font-size: 2rem;
                line-height: 2.4rem;
                text-align: right;
                letter-spacing: 0.02em;
                word-spacing: -.2rem;
                color: #222222;
                
                span{
                    color: #8F8F8F;
                    margin-left: .6rem;
                }
            }

            p{
                font-family: var(--ftl);
                font-style: normal;
                font-weight: 300;
                font-size: 1.05rem;
                line-height: 1.9rem;
                text-align: right;
                letter-spacing: 0.02em;
                color: #7F7F7F;
                margin-bottom: 1.25rem;
            }

            .more-link{
                padding: 0 .2rem;

                a{
                    font-family: var(--ftl);
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.1rem;
                    line-height: 1.1rem;
                    text-align: right;
                    letter-spacing: 0.025em;
                    text-decoration-line: underline;
                    color: #003399;
                    text-decoration: none;
                    word-spacing: -.1rem;
                    opacity: .8;
                    transition: all .2s ease;
                    
                    &:hover{
                        opacity: 1;
                    }

                    &:hover svg{
                        margin-right: .4rem;
                    }
                
                    svg{
                        transition: all .2s ease;
                        margin-right: .1rem;
                    }

                }
            }

        }
    }
    .reversed{
        flex-direction: row-reverse;
    }


`


export const MdStyled = styled.section`

margin-top:2rem;

.card{
    -webkit-box-shadow: 0px 0px 16px -3px rgba(0,51,153,0.27);
    -moz-box-shadow: 0px 0px 16px -3px rgba(0,51,153,0.27);
    box-shadow: 0px 0px 16px -3px rgba(0,51,153,0.27);
    margin-bottom: 7rem;
    border: none;
    background-color: #fff;

    .img-con{
        height: 330px;
        width: 100%;
        border-radius:7px 7px 0  0 ;
        overflow: hidden;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.125));
        }
    }

    .content{
        align-items: center;
        padding: 1.8rem 1.4rem;

        h4{
            margin-bottom: 0.8rem;
            font-family: var(--ftl);
            font-weight: bold;
            font-size: 2rem;
            line-height: 2.5rem;
            text-align: right;
            letter-spacing: 0.02em;
            word-spacing: -.15rem;
            color: #222222;
            
            span{
                color: #8F8F8F;
                margin-left: .6rem;
            }
        }

        p{
            margin-right: 0.4rem;
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1.05rem;
            line-height: 2rem;
            text-align: right;
            letter-spacing: 0.02em;
            color: #7F7F7F;
            margin-bottom: 1.5rem;
        }

        .more-link{
            padding: 0 .2rem;

            a{
                font-family: var(--ftl);
                font-style: normal;
                font-weight: 300;
                font-size: 1.1rem;
                line-height: 1.1rem;
                text-align: right;
                letter-spacing: 0.025em;
                text-decoration-line: underline;
                color: #003399;
                text-decoration: none;
                word-spacing: -.1rem;
                opacity: .8;
                transition: all .2s ease;
                
                &:hover{
                    opacity: 1;
                }

                &:hover svg{
                    margin-right: .4rem;
                }
            
                svg{
                    transition: all .2s ease;
                    margin-right: .1rem;
                }
            }
        }
    }
}

@media screen and (max-width:991px){

    .card{
        margin-bottom: 3.5rem;

        .content{
            padding: 2rem;

            p{
                margin-right: 0;
            }
        }
    }
}


@media screen and (max-width:576px){
    .card{
        margin-bottom: 3.5rem;

        .img-con{
            height: 250px;
        }

        .content{
            h4{
                font-size: 1.75rem;
                line-height: 2.25rem;
            }
        }
    }
}

`
