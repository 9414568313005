import { Link } from 'react-router-dom';
import Styled from './Styled.styled'

import Image from 'src/assets/images/about.jpg';



function About() {
    return (
        <Styled className='container'>
            <div className='row' data-aos="fade-up" >
                <div className='col-12 col-lg-6'>
                    <div className='img-con'>
                        <img src={Image} alt="About Us" width={450} height={400} />
                        <span className='first'></span>
                        <span></span>
                    </div>
                </div>
                <div className='col-12 col-lg-6 content-con'>
                    <div className="content">
                        <h4 className='title'>
                            عن الرقيم المحاسبي
                        </h4>
                        <p className='text'>
                            تأسست شركة الرقيم المحاسبي بصورتها الحالية في أكتوبر 2021، وتستند في العمل المحاسبي على خبرة مؤسسيها كلاً من الاستاذ خالد الفيتوري المدير العام، ومدير العمليات المحاسب والمراجع القانوني والخبير الحسابي المقيد بالمحاكم الليبية الدكتور محمد عمر عبدالرحيم بصفتهم خبراء في مجال المحاسبة والمراجعة مقيدين بنقابة المحاسبين ويعملون بموجب إذن مزاولة المهنة رقم 1443 – 2007.
                        </p>
                        <div className='btn-con'>
                            <Link to='/about'>
                                المزيد

                            </Link>
                        </div>


                    </div>
                </div>

            </div>

        </Styled>
    )
}

export default About;