import {
    IoPeopleOutline, IoShieldCheckmarkOutline,
    IoTimeOutline, IoConstructOutline, IoDiamondOutline
} from 'react-icons/io5';
import { TbTargetArrow } from 'react-icons/tb';

import { IconType } from 'react-icons';

export type FeatureType = {
    id: string,
    icon: IconType,
    title: string,
    text: string
}

export type FeaturesType = FeatureType[]

export const features: FeaturesType = [
    {
        id: 'feature-1',
        icon: IoPeopleOutline,
        title: "فريق عمل متكامل",
        text: `
        يتميز فريق عملنا بتنوع المجالات والاقسام والخبرات المتراكمة والعلاقات المميزة الممتدة
        فريق مؤهل من الخبراء المحاسبين جاهزين لإنجاز جميع الخدمات المحاسبية للأفراد والشركات

        `

    },
    {
        id: 'feature-2',
        icon: IoShieldCheckmarkOutline,
        title: "الخصوصية",
        text: "إضافة إلى التزامنا التام بالمسئوليات والواجبات الوظيفية المنوطة بعملائنا وانطلاقا من أهمية قيمة الامانة فإننا في شركتنا نولي لخصوصية عملائنا قدرا كبيرا من المسئولية والاهتمام والرعاية في الحفاظ على معلوماتهم المالية والمحاسبية"
    },
    {
        id: 'feature-3',
        icon: TbTargetArrow,
        title: "الدقة المهنية",
        text: "نسعى دائما للتميز وإنجاز المهام بأعلى مستوى من الجودة والإتقان من خلال فريق العمل المميز الذي يملك الوعي والإدراك لأهمية العميل والتدريب اللازم فهدفنا بناء علاقة مهنية قوية مع كافة عملائنا."
    },
    {
        id: 'feature-4',
        icon: IoTimeOutline,
        title: "الحفاظ على الوقت",
        text: "ندرك تماما أن الوقت عامل هام في نجاح أي شركة أو مشروع اقتصادي لذلك أخذنا على عاتقنا مسؤولية إنجاز المهام الموكلة إلينا في أسرع وقت ممكن مع مراعاة عاملي الدقة والإتقان."
    },
    {
        id: 'feature-5',
        icon: IoConstructOutline,
        title: "الخدمات المهنية",
        text: `
        
        نجتهد دائما على تنوع خدماتنا لتتوافق مع احتياجات عملائنا مع اعطاء العناية الكاملة لكل التفاصيل مهما كانت صغيرة، لذا تقـدم شركتنا خـدمات مهنيـة في مجـال المحاسـبة والمراجعـة ودراسات الجدوى
        وغيرها الكثير الكثير
        `
    },
    {
        id: 'feature-6',
        icon: IoDiamondOutline,
        title: "القيم الجوهرية التي نؤمن بها",
        text: `
        
        نسعى دائما لإنجاز أعمالنا بطريقة مطابقة للمعايير المهنية
        ,
        علاقتنا بعملائنا يحكمها الموضوعية والصدق والامانة
        ,
        نقوم بمعاملة جميع معلومات العميل بمنتهى السرية
        
        `
    }
]