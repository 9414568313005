import { styled } from 'styled-components';


export default styled.div`
    background-color: #fff;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;

`

