import Styled from './Styled.styled';
import Spinner from '../Spinner/Spinner';

function LoadingPage() {
    return (
        <Styled>
            <Spinner height={100} width={100} />
        </Styled>)
}

export default LoadingPage