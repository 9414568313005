import { styled } from 'styled-components';


export default styled.section`
    padding: 6rem 0;
    
    .img-con{
        position: relative;
        margin: auto;
        width: 450px;
        height: 400px;

        img{
            border-radius: 0px 50px;
            width: 100%;
            height: 100%;
            box-shadow: rgba(0, 0, 0, 0.1) 10px 10px 15px -3px, rgba(0, 0, 0, 0.05) 5px 4px 6px -2px;
        }

        span{
            bottom: 35px;
            right: -30px;
            width: 65px;
            height: 40px;
            border-radius: 0 1.6rem;
            background: var(--primary);
            display: inline-block;
            position: absolute;
            box-shadow: rgba(0, 0, 0, 0.1) 10px 10px 10px -3px,rgba(0, 0, 0, 0.05) 5px 4px 3px -2px;
        }

        span.first{
            bottom: 90px;
        }

    }

    .content-con{
        display: flex;
        align-items: center;
    }

   .title{
        font-family: var(--ftl);
        font-size: 2.5rem;
        line-height: 3rem;
        text-align: right;
        letter-spacing: 0.02em;
        color: #222222;
        font-weight: 600;
        word-spacing: -0.2rem;
        margin-bottom: 1.25rem;
   }

   .text{
        font-family: var(--ftl);
        font-style: normal;
        font-weight: 300;
        font-size: 1.1rem;
        line-height: 2.1rem;
        text-align: right;
        letter-spacing: 0.015em;
        color: #7F7F7F;
        margin-bottom: 1.5rem;
   }

   .btn-con{
        padding-top: .8rem;
   }

   .btn-con a{
        background: var(--primary);
        color: #fff;
        padding: .5rem 2rem;
        text-decoration: none;
        font-family: var(--ftl);
        font-size: 1.1rem;
        border-radius: 4px;
        letter-spacing: 0.03rem;
        transition: all .15s ease-in-out;

        &:hover{
            background-color: #002774;
        }
   }

   .btn-con svg{
        margin-right: 0.3rem;
   }

    @media (max-width: 991px){
        padding: 4rem 0;
        
        .img-con{
            margin-bottom: 2.5rem;
            width: auto !important;
            height: 400px;
        
            img{
                border-radius:1rem;
            }

            span{
                display: none;
            }
        }
    }

   @media (max-width: 767px){

        .img-con{
            margin-bottom: 1.5rem;
            height: 375px;
        }
   }

   @media (max-width: 576px){
    padding: 4rem 1.5rem;

    .img-con{
        margin-bottom: 2.5rem;
        width: auto ;
        height: 300px;
    
        img{
            border-radius:1rem;
        }

        span{
            display: none;
        }
    }

    .content-con{
        .content{
            .title{
                font-size: 2.25rem;
                margin-bottom: .75rem;
            }
        }
    }



   }

`