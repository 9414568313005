
import Styled from './Styled.styled';
import SpinnerImage from 'src/assets/svg/spinner.svg';


function Spinner({ width, height }: any) {
  return (
    <Styled>
      <img src={SpinnerImage} alt='Spinner'
           height={height} width={width} />
    </Styled>)
}

export default Spinner