import React from 'react'

import Styled from './Styled.styled'
import Image from 'src/assets/images/hero.jpg';


function Hero() {
    return (
        <Styled>
            <img width={'100%'} height={'100%'} src={Image} alt={"شركة الرقيم المحاسبي"} />
        </Styled>
    )
}

export default Hero