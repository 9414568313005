import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IoSearchOutline } from 'react-icons/io5';

import { Styled } from './Styled.styled';
import courseImage from 'src/assets/images/course_header.jpg';
import CoursesPagination from './Pagination';
import Post from './Post';

import { Spinner, Error as MyError } from 'src/Components';

import BASE_URL from 'src/constants/vars';





export type PostType = {
    id: number,
    name: string,
    overview: string
    slug: string
    level: string,
    image: string,
    instructor: string
    price: string
}


function List() {
    const [text, setText] = useState("");
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1);


    const featchCourses = async () => {
        const res = await fetch(`${BASE_URL}/courses/?page=${page}${search ? `&search=${search}` : ''}`);
        const jsonData = await res.json();
        return jsonData
    }

    const { data, error, isLoading, isError, isFetching } = useQuery({ queryKey: ['coursesData', page, search], queryFn: () => featchCourses(), keepPreviousData: true })

    const pagesNum = data !== undefined ? data.pages_num : 0;


    const onSubmit = (evt: any) => {
        evt.preventDefault();
        if (text) setSearch(text);
    };

    const onChange = (evt: React.FormEvent<HTMLInputElement>) => setText(evt.currentTarget.value);



    if (isError) {
        let msg: string = "حدث خطأ ما"
        if (error instanceof Error) {
            msg = error.message;
        }
        return <MyError msg={msg} />
    }

    return (
        <Styled>
            <header>
                <div className='title'>
                    <h4>
                        تصفح دوراتنا التدريبية
                    </h4>
                </div>
                <img src={courseImage} />
            </header>
            <section className='container'>
                <div className='s-header'>
                    <h4>
                        الدورات التدريبية
                    </h4>

                    <form onSubmit={onSubmit}>
                        <input
                            type="text"
                            name="search"
                            placeholder="البحث"
                            value={text}
                            onChange={onChange}
                        />

                        <button type="submit">
                            <IoSearchOutline size={20} />
                        </button>
                    </form>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>

                            {isLoading || isFetching ?
                                <div className='my-5'>
                                    <Spinner height={70} width={70} />
                                </div>
                                :
                                data !== undefined ?
                                    data?.results.length > 0 ? data.results.map((item: PostType) => (
                                        <div key={item.slug} className='col-12 col-md-6 col-lg-4'>
                                            <Post item={item} />
                                        </div>
                                    )) : <p className='not-found'>
                                        لا يوجد أي دورات تدريبية
                                    </p>
                                    :
                                    []
                            }

                        </div>
                    </div>
                    {
                        pagesNum > 0 ?
                            <div className='col-12'>
                                <CoursesPagination page={page}
                                    next={!isError ? data.next : null}
                                    previous={!isError ? data.previous : null}
                                    setPage={setPage}
                                    pagesNum={pagesNum} />
                            </div> :
                            null
                    }
                </div>
            </section>
        </Styled>
    )
}

export default List