import Styled from './Styled.styled';


import {
    IoDiamondOutline, IoEyeOutline,
    IoMegaphoneOutline, IoLocate
} from 'react-icons/io5';

function About() {
    return (
        <Styled className='container'>
            <div className='row'>
                <div className='col-12 col-lg-8 m-auto'>
                    <div className="main-content">
                        <h4 className='title'>
                            عن الرقيم المحاسبي
                        </h4>
                        <p className='text'>
                            تأسست شركة الرقيم المحاسبي بصورتها الحالية في أكتوبر 2021، وتستند في العمل المحاسبي على خبرة مؤسسيها كلاً من الاستاذ خالد الفيتوري المدير العام، ومدير العمليات المحاسب والمراجع القانوني والخبير الحسابي المقيد بالمحاكم الليبية الدكتور محمد عمر عبدالرحيم بصفتهم خبراء في مجال المحاسبة والمراجعة مقيدين بنقابة المحاسبين ويعملون بموجب إذن مزاولة المهنة رقم 1443 – 2007.
                        </p>
                        <div className='underline'>

                        </div>
                    </div>
                </div>
                <div className='w-100'></div>
                <div className='col-12 col-md-4'>
                    <div className='item'>
                        <div className='icon'>
                            <IoDiamondOutline size={65} />
                        </div>
                        <div className='title'>

                            <h4>
                                قيمنا

                            </h4>

                        </div>
                        <div className='text'>
                            المهنية، الموضوعية، الاستقلالية والتدريب المستمر.
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4'>
                    <div className='item'>
                        <div className='icon'>
                            <IoEyeOutline size={65} />
                        </div>
                        <div className='title'>
                            <h4>
                                رؤيتنا
                            </h4>

                        </div>
                        <div className='text'>
                            وراء كل مشروع ناجح محاسب ناجح "الرقيم المحاسبي"
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4'>
                    <div className='item'>
                        <div className='icon'>
                            <IoMegaphoneOutline size={65} />
                        </div>
                        <div className='title'>
                            <h4>
                                رسالتنا
                            </h4>

                        </div>
                        <div className='text'>
                            نحو بناء وحدات اقتصادية قوية للوصول إلى اقتصاد أفضل من آجل مجتمع أكثر رفاهية.
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-8 m-auto mt-md-5'>
                    <div className='item mb-0'>
                        <div className='icon'>
                            <IoLocate size={65} />
                        </div>
                        <div className='title'>
                            <h4>
                                أهدافنا
                            </h4>

                        </div>
                        <div className='text'>
                            تهدف الشركة إلى تقديم خدمات محاسبية متعددة ومتكاملة في شكل منظومة معلوماتية تساهم في تحقيق العدالة والشفافية في الوحدات الاقتصادية بالبيئة المحلية من خلال:
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-9 px-md-5 m-auto'>
                    <ul>
                        <li>
                            المساهمة في توفير بيئة العمل المناسبة للمؤسسات الاقتصادية من خلال تقديم الاستشارات وتوفير المعلومات المالية المناسبة لاتخاذ القرارات الرشيدة.
                        </li>
                        <li>
                            المساهمة في تنمية وتطوير مهنة المحاسبة من خلال العمل على تطبيق المعايير المحاسبية الدولية والاسترشاد بالممارسات العالمية السائدة والتي تلائم البيئة الليبية ومحاولة توطينها لتحسين جودة المهنة.
                        </li>
                        <li>
                            المساهمة في تنمية مهارات المحاسبين عن طريق تنفيذ برامج تدريبية في مجالات المحاسبة والمالية.
                        </li>
                    </ul>
                </div>

            </div>

        </Styled>
    )
}

export default About;