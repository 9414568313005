import React from 'react'

import crmImg from 'src/assets/images/services/crm.jpg';
import clientsImg from 'src/assets/images/services/clients.png';
import { Styled } from './Styled.styled';


// Feasibility studies and estimated budgets
function CRM() {
    return (
        <Styled>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-7 content'>
                        <div className='sm-title'>
                            من خدماتنا /
                        </div>
                        <div className='title'>
                            <h4>
                                إدارة علاقات العملاء
                            </h4>
                            <div className='underline'>

                            </div>
                        </div>

                        <div>
                            <div className='text'>
                                <p>
                                    يعتبر نظام إدارة علاقات العملاء CRM واحدا من أهم الحلول التي تتيح متابعة النشاطات بحيث يعمل في الدرجة الأولى على جعل العميل محور الاهتمام الأساسي فيساعد على تكوين قاعدة بيانات متكاملة تستطيع عن طريقه المنشأة إنجاز كافة الأعمال واعمال المتابعة وفتح الحسابات للعملاء وحفظ كافة الأحداث التي تتم بشكل يومي وإصدار التقارير وترتيب الأعمال ومعاينة كفاءة الموظفين في الأداء وإبقاء العلاقة الطيبة مع العميل
                                    <br />
                                    وهوعبارة عن مجموعة من الحلول البرمجية المتكاملة القائمة على البيانات التي تساعد في إدارة المعلومات المتعلقة بالعملاء الحاليين والمحتملين لشركتك وتتبعها وتخزينها. من خلال الاحتفاظ بهذه المعلومات في نظام مركزي، يمكن لفرق العمل الوصول إلى الرؤى التي يحتاجون إليها، في اللحظة التي يحتاجون إليها.
                                </p>
                                <img src={clientsImg} />
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className='img-con'>
                            <img src={crmImg} />
                        </div>
                    </div>



                </div>
            </div>

        </Styled>
    )
}


export default CRM;