import React from 'react'

import fsebImg from 'src/assets/images/services/fseb.jpg';
import { Styled } from './Styled.styled';


// Feasibility studies and estimated budgets
function FSEB() {
    return (
        <Styled>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-7 content'>
                        <div className='sm-title'>
                            من خدماتنا /
                        </div>
                        <div className='title'>
                            <h4>
                                دراسات الجدوى والموازنات التقديرية
                            </h4>
                            <div className='underline'>

                            </div>
                        </div>

                        <div>
                            <div className='text'>
                                <p>
                                    لدينا فريق متكامل متخصص في اعداد دراسات الجدوى الاقتصادية وتقييم المشروعات واعداد الخطط التسويقية وتحليل الاسواق واعداد الدراسات التحليلية للقوائم المالية لمساعده اداره المنشاة في اتخاذ القرار خاصه عندما تكون بصدد الاختيار بين عده بدائل قبل تمويل توسعات مقترحه او عند شراء اصول رأسمالية.
                                    <br />
                                    <br />
                                    أيضاً لدينا فريق متخصص لمساعده العميل في الحصول على القروض المصرفية والتسهيلات اللازمة للتمويل من المصارف الليبية وانهاء كافة الاجراءات المتعلقة بالاقتراض، ومساعده الشركات سواء المتعثرة في التسويات مع المصارف او في الوقوف على اسباب التعثر ووضع الحلول الملائمة
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className='img-con'>
                            <img src={fsebImg} />
                        </div>
                    </div>
                    <div className='col-12 content'>
                        <div className='text'>
                            <p>
                                وتعني دراسة السوق للوقوف على مدي إمكانيه تطبيق المشروع ونجاحه وتوضيح الاستثمارات المطلوبة والعائد المتوقع والمؤثرات الخارجية على المشروع مثل قوانين الدولة والمُنافسة والتطور التقني.
                                <br />
                                دراسات الجدوى الاقتصادية للمشروعات من العوامل الهامة والمؤثرة جدا عند اختيارك لمشروع استثماري جديد حيث ان الهدف الأساسي من دراسة الجدوى هو دراسة امكانية قيام المشروع وتحقيق اقصى عائد ممكن من الموارد المتاحة وتحقيق ارباح او عائد يفوق التكلفة المستثمرة فيه والوصول الى قرار نهائي بقبول الفكرة او رفضها.
                            </p>
                        </div>
                        <div className='sub-title mt-5'>
                            <h4>
                                مراحل دراسة الجدوى الاقتصادية للمشروعات:
                            </h4>
                        </div>
                        <div className='text'>
                            <p className='bk'>
                                أولا: الدراسة السوقية:
                            </p>
                            <p>
                                تحديد مدى أهمية المشروع للسوق، تحديد الفئات المستهدفة، تحديد المنافسين، قياس مدى استيعاب السوق للمنتج، دراسة العرض والطلب على المنتج، دراسة مصغرة لأساليب التسويق المتبعة للمنتج واقتراح أنسب الطرق. التسويقية للوصول للمبيعات المستهدفة
                            </p>

                            <p className='bk'>
                                ثانيا: الدراسة الفنية:
                            </p>
                            <p>
                                دراسة واختيار موقع المشروع، تخطيط العملية الانتاجية ومراحل الانتاج، اختيار وتوصيف معدات الانتاج، تحديد دورة التشغيل، دراسة التكاليف الثابتة والمتغيرة، تقدير التكاليف الاستثمارية المطلوبة للمشروع.
                            </p>

                            <p className='bk'>
                                ثالثا: الدراسة المالية:
                            </p>
                            <p>
                                دراسة الايرادات واسعار البيع المقترحة، عمل قوائم الدخل وقوائم التدفقات النقدية المستقبلية للمشروع.
                            </p>

                            <p className='bk'>
                                رابعا: المؤشرات المالية والاقتصادية:
                            </p>
                            <p>
                                تحليل الربحية والسيولة وفترة استرداد رأس المال.
                            </p>

                            <p className='bk'>
                                خامسا: تقييم المشروع:
                            </p>
                            <p>
                                توصيات عامة عن المشروع من وجهة النظر الاقتصادية والاجتماعية.
                            </p>
                        </div>

                    </div>

                </div>
            </div>

        </Styled>
    )
}

export default FSEB