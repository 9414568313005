import { Routes, Route } from 'react-router-dom';

import './setup/i18n/i18n';

import { Home, About, CoursesList, CoursesDetail } from './pages';
import { ASD, REOC, SCEC, FSEB, CRM } from './pages/services';
import Layout from './layouts/Layout/Layout';
import { Page404, LoadingPage } from 'src/Components';




function App() {

	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path='about' element={<About />} />
				<Route path='serivces'>
					<Route path='accounting-and-systems-design-services' element={<ASD />} />
					<Route path='review-and-evaluation-of-companies' element={<REOC />} />
					<Route path='studies-consultations-establishing-companies' element={<SCEC />} />
					<Route path='feasibility-studies-and-estimated-budgets' element={<FSEB />} />
					<Route path='customer-relationship-management' element={<CRM />} />
				</Route>
				<Route path='courses/'>
					<Route index element={<CoursesList />} />
					<Route path=':id' element={<CoursesDetail />} />
				</Route>
				<Route path="*" element={<Page404 />} />
			</Route>
		</Routes>
	);
}

export default App;
