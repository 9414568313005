

export const sectionList = [
    {
        key: "1-s",
        title: "خدمات المحاسبة وتصميم النظم",
        overview: `
        نهـدف إلى تقـديم معلومـات ماليـة دقيقـة إلى عملائنا وفي الوقـت المناسـب لأن السـجلات الماليـة المنتظمـة تؤكـد أن ‏المؤسسة تعمـل بصـورة أكثـر كفاءة. ‏ تقـوم الشركة بتقديـم مجموعـة مـن الخـدمات المحاسـبية للشـركات والمنظمـات غيـر الهـادفة للربـح تتضمـن ‏مسـك الدفاتـر والسـجـلات، وتقديم الخـدمات المرتبطـة بالأجـور والمرتبـات، وإعـداد القوائـم الماليـة، وتصـميم ‏وتطـوير النظـم المحاسـبية، وتقديم الاقرارات الضريبية وتمثيل الشركة أمام مصلحة الضرائب.        
        `,
        link: '/serivces/accounting-and-systems-design-services',
        image: require('src/assets/images/services/asd.jpg')
    },
    {
        key: "2-s",
        title: "مراجعة وتقييم الشركات",
        overview: `
        هي عمليات وإجراءات وأعمال يقوم بها طاقمنا المهني لإبداء الرأي حول البيانات المالية لعملائنا وفقاً لمعايير المراجعة المقبولة والمتعارف عليها، والمعايير الدولية، بالإضافة إلى ذلك فإن أعمال المراجعة مصممة لتقديم المشورة لأصحاب الأعمال، والمدراء بما يفيدهم في اتخاذ قراراتهم وتحقيق أهداف شركاتهم، جميع أعمال المراجعة نبدأها بفهم وتقييم طبيعة أعمال الشركة المراد مراجعتها.
        `,
        link: '/serivces/review-and-evaluation-of-companies',
        image: require('src/assets/images/services/reoc.jpg')
    },
    {
        key: "3-s",
        title: "إعداد الدراسات وتقديم الاستشارات وتأسيس الشركات",
        overview: `
        تقـدم الشركة لعملائها الاستشارات التي تسـاعدها في اتخاذ القـرار المناسـب عنـد تأسـيس الشركات مـن حـيث ‏الشـكل القانوني وتحديد الاحتياج التمويلي المطلوب عن طريق إجراء دراسات الجدوى الاقتصادية، وكذلك هيكلة رأس المـال واختيار أفضل الأشكال القانونية لتأسيس الشركات للحصول على أفضل المزايا القانونية والـضريبية،
        
        `,
        link: '/serivces/studies-consultations-establishing-companies',
        image: require('src/assets/images/services/scec.jpg')
    },
    {
        key: "4-s",
        title: "دراسات الجدوى والموازنات التقديرية",
        overview: `
        تقـوم الشركة بأعداد دراسات الجدوى الاقتصادية للمشروعات سواء المشروعات المزمع انشاؤها او دراسات الجدوى المطلوبة نظاماً مثل دراسات جدوى تحول الشركات ذات المسئولية المحدودة الى شركات مساهمة او دراسات جدوى تأسيس الجمعيات التعاونية كذلك دراسات جدوى لمشروعات المنشآت غير الهادفة للربح كما تقـوم الشركة بمراجعة الموازنات التقديرية الخاصة بالنمشات القائمة.
        
        `,
        link: '/serivces/feasibility-studies-and-estimated-budgets',
        image: require('src/assets/images/services/fseb.jpg')
    },
    {
        key: "5-s",
        title: "إدارة علاقات العملاء",
        overview: `
        يعتبر نظام إدارة علاقات العملاء CRM واحدا من أهم الحلول التي تتيح متابعة النشاطات بحيث يعمل في الدرجة الأولى على جعل العميل محور الاهتمام الأساسي فيساعد على تكوين قاعدة بيانات متكاملة تستطيع عن طريقه المنشأة إنجاز كافة الأعمال واعمال المتابعة وفتح الحسابات للعملاء وحفظ كافة الأحداث التي تتم بشكل يومي وإصدار التقارير وترتيب الأعمال ومعاينة كفاءة الموظفين في الأداء وإبقاء العلاقة الطيبة مع العميل
        
        `,
        link: '/serivces/customer-relationship-management',
        image: require('src/assets/images/services/crm.jpg')
    }
]
