import { styled } from 'styled-components';


export default styled.article`
    padding: 6rem 0;

    .container{
        .img-con{
            height: 400px;
            width: 100%;
            border-radius:10px;
            overflow: hidden;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center top;
            }
        }
        .content{
            .cats{
                display: flex;
                align-items: center;
                gap: .5rem;
                flex-wrap: wrap;
                margin-top: 1.5rem;

                & > div{
                    
                    padding:.5rem 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #DBE7FF;
                    border-radius: 42.1569px;
                    font-family: var(--ftl);
                    font-style: normal;
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 0.01em;
                    color: rgba(51, 51, 51, 0.65);

            }
        }

        .title{
            padding-top: 1.25rem;

            h4{
                font-family: var(--ftl);
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 3.2rem;
                text-align: right;
                letter-spacing: 0.02em;
                color: #222222;

            }
        }
        .ins{
            margin-top: .25rem;
            display: flex;
            align-items: center;
            gap: .5rem;
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1.1rem;
            letter-spacing: 0.02em;
            color: #8f8f8f;
        }

        .text{
            margin-top:1rem;
            font-family: var(--ftl) !important;
            font-style: normal !important;
            font-weight: 300;
            line-height: 2rem !important;
            letter-spacing: 0.01em !important;
            color: #5F5F5F;
        }
        }


        .my-card{
            padding: 1.75rem 1.5rem 1rem;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
            border-radius: 5px;

            .title h4{
                margin-bottom: 1.25rem;
                text-align: center;
                font-weight: bold;
                font-family: var(--ftl);
                font-size: 1.75rem;
                line-height: 1.75rem;
                letter-spacing: 0.02em;
                color: #222222;
                word-spacing: -.2rem;
            }

            .info{
              
                & > div{
                    padding: 0.5rem .75rem;
                    display: flex;
                    align-items: center;
                    gap: .75rem;

                    .icon{
                        color: var(--primary);
                    }

                    .text{
                        font-family: var(--ftl);
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.15rem;
                        line-height: 1rem;
                        letter-spacing: 0.02em;
                        color: #7F7F7F;
                    }

                    .price{
                        font-weight: bold;
                        color: #222;
                        font-size: 1.16rem;
                    }
                }
            }

            .btn-con{
                padding: 1rem 0.5rem;
                text-align: center;
                
                a{
                    text-decoration: none;
                    padding: 0.25rem;
                    margin-top: .5rem;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #003399;
                    border-radius: 5px;
                    font-family: var(--ftl);
                    font-size: 1.1rem;
                    line-height: 2.5rem;
                    text-align: right;
                    letter-spacing: 0.03em;
                    color: #FFFFFF;
                    transition: background-color .2s ease-in-out;

                    &:hover{
                        background-color: #002879;
                    }
                }
            }

            .files{
              
              & > a{
                margin-bottom: 0.25rem;
                border-radius: 5px;
                padding: 0.25rem .5rem;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: background .2s ease;
                
                &:hover{
                    background: #f9f9f9;
                }
                  .icon{
                        color: var(--primary);
                        display: flex;
                        padding: 0.3rem;
                        border-radius: 50%;
                        color: #777;
                        transition: none;

                        &:active{
                            color: var(--primary);
                        }
                  }

                  .text{
                        line-height: 1.5rem;
                        font-family: var(--ftl);
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.1rem;
                        letter-spacing: 0.02em;
                        color: #7F7F7F;
                  }

                //   .price{
                //       font-weight: bold;
                //       color: green;
                //       font-size: 1.16rem;
                //   }
              }
          }

        }
    }

    @media screen and (max-width:991px){
        .container{
            .my-card{
                margin-top: 2rem;
            }
        }
    }

    @media screen and (max-width:576px){
        .container{
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            .img-con{
                height: 300px;
            }

            .my-card{
                margin-top: 2rem;
            }
        }
    }

`

