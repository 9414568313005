import { PostStyled } from './Styled.styled';

import { useNavigate } from 'react-router-dom';
import { levels, levelsType } from 'src/constants/vars';


function Post(props: any) {
    const navigate = useNavigate();
    const item = props.item;

    return (<PostStyled onClick={() => {
        navigate(`${item.slug}/`)
    }}>
        <div className='img-con'>
            <img src={item.image} alt={item.name} />
        </div>
        <div className='content'>
            <h4>
                {item.name}
            </h4>
            <div className='insr'>
                المدرب  \ {item.instructor}
            </div>
            <p className='overview'>
                {item.overview}
            </p>
            <div className='divider'>

            </div>
            <div className='footer'>
                <div className='level'>
                    المستوى / <span>
                        {levels[item.level as keyof levelsType]}
                    </span>
                </div>
                <div className='price'>
                    {item.price}
                </div>
            </div>
        </div>
    </PostStyled>
    )
}


export default Post