import Styled from './Styled.styled';

import { IoPeopleOutline } from 'react-icons/io5';
import { features } from 'src/constants/data/Features';



function Features() {
    return (
        <Styled className='container'>
            <div className="title mb-5">
                <h4>
                    ما يميزنا عن غيرنا
                </h4>
                <div className="underline">

                </div>
            </div>
            <div className='row'>
                {features.map((item) => (
                    <div data-aos-delay="400" data-aos-duration="600"
                        data-aos="fade-up" className='col-12 col-lg-4 col-md-6 mb-5' key={item.id}>
                        <div className='f-card'>
                            <div className='icon'>
                                <item.icon size={70} />
                            </div>
                            <div className='title'>
                                {item.title}
                            </div>
                            <div className='text'>
                                {item.text}
                            </div>
                        </div>
                    </div>
                ))}

            </div>

        </Styled>
    )
}

export default Features