import Pagination from 'react-bootstrap/Pagination';
import { PaginationStyled } from './Styled.styled';


function CoursesPagination(props: any) {
    return (
        <PaginationStyled>
            <Pagination>
                {props.previous !== null && (
                    <Pagination.Prev
                        onClick={() => {
                            props.setPage((oldPage: number) => oldPage - 1);
                        }}
                    />
                )}

                {new Array(props.pagesNum).fill(0).map((_, index) => {
                    return (
                        index + 1 > props.page - 3 && index + 1 < props.page + 3 ?
                            <Pagination.Item key={`p-${index}`} disabled={props.page == index + 1}
                                active={props.page == index + 1}
                                onClick={() => props.setPage((index) + 1)}
                            >{index + 1}</Pagination.Item>
                            : null)
                })}

                {props.next !== null && (
                    <Pagination.Next
                        onClick={() => {
                            props.setPage((oldPage: number) => oldPage + 1);
                        }}
                    />
                )}
            </Pagination>
        </PaginationStyled>

    );
}

export default CoursesPagination;