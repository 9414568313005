import Styled from './Styled.styled';
import { useRef } from 'react';
import { LgScreen, MdScreen } from "./Screens"

function Services() {
    const windowSize = useRef({ 'width': window.innerWidth });


    const Component = windowSize.current.width > 991 ? LgScreen : MdScreen;

    return (
        <Styled>
            <div className="title">
                <h4>
                    من أبرز خدماتنا
                </h4>
                <div className="underline">

                </div>
            </div>
            <Component />
        </Styled>
    )
}

export default Services