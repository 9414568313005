import { styled } from 'styled-components';


export const Styled = styled.article`
    header{
        height: 300px;
        position: relative;
        object-fit: cover;
        object-position: center top;
        
        .title{ 
            display: flex;
            align-items:center;
            justify-content: center;
            position: relative;
            z-index: 999;
            height: 100%;
            background-color: #0033999e;

            h4{
                color: #fff;
                font-family: var(--ftl);
                font-size: 3rem;
                line-height: 3rem;
                text-align: right;
                letter-spacing: 0.02em;
            }

        }

        img{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center top;
        }
    }

    section.container{
        padding: 6rem 0;

        .s-header{
            padding: 0 0 1.5rem 0;
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #DDDDDD;

            h4{
                margin-bottom: 0;
                font-family: var(--ftl);
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 2.5rem;
                text-align: right;
                letter-spacing: 0.02em;
                color: #222222;
            }

            form {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin-bottom: 0;

                input{
                    width: 300px;
                    padding: .6rem 1.5rem;
                    background: #F0F0F0;
                    border-radius: 30px;
                    border: unset;
                    font-family: var(--ftl);
                    font-size: 1.1rem;
                    font-weight: 300;
                    letter-spacing: 0.02rem;
                    color: #222;
                }

                button {
                    background-color: var(--primary);
                    color: #fff;
                    padding: 0.5rem;
                    border-radius: 50%;
                    transition: background-color .2s ease-in-out;
                    border: none;

                    &:hover{
                        background-color: #002879; 
                    }
            }
        }
    }

    .not-found{
        font-family: var(--ftl);
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #7F7F7F;
    }
}

    @media screen and (max-width:768px){
        header{
            .title{
                h4{
                    font-size: 2.25rem;
                }
            }
        }

        section.container{
            .s-header{
                flex-wrap: wrap;
                justify-content: center;
                
                h4{
                    margin-bottom: 1.5rem;
                }
                form{
                    width: 100%;

                    input{
                        width: 90%;
                    }
                }
            }
        }
    }

    @media screen and (max-width:576px){
        section.container{
            padding: 4rem 1.25rem;
        }
    }
`



export const PostStyled = styled.div`
    cursor: pointer;
    margin-bottom: 2rem;
    border-radius: 5px;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);


    .img-con{
        height: 240px;
        
        img{
            width: 100%;
            height: 100%;
            object-position: center top;
            object-fit: cover;
        }
    }

    .content{
        padding: 1.5rem 1.5rem 0.75rem;

        h4{
            font-family: var(--ftl);
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 2.25rem;
            text-align: right;
            letter-spacing: 0.02em;
            color: #222222;
            margin-bottom: .35rem;        
        }

        .insr{            
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            line-height: 1rem;
            letter-spacing: 0.01em;
            color: #7E7E7E;
        }

        .overview{
            margin-top: .7rem;
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            line-height: 1.75rem;
            letter-spacing: 0.02em;
            color: #555;
            margin-bottom: 1.25rem;
        }

        .divider{
            height: 1px;
            background: #D9D9D9;
        }

        .footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.25rem 0 .75rem 0;
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1.1rem;
            line-height: 1.1rem;
            letter-spacing: 0.01em;

            .level{   
                    color: #7F7F7F;
                    letter-spacing: 0.03em;

                    span{
                        color: #222;
                        font-weight: bold;
                        letter-spacing: 0.01em;
                    }
                }

        .price{
            color: #003399;
            font-weight: bold;
            }
        }
    }

`

export const PaginationStyled = styled.div`
    .pagination{
        justify-content: center;
        gap: .5rem;
        margin-top: 1.5rem;
        padding: 0;

        .page-item {

            .page-link{
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #F1F1F1;
                border-radius: 10px;
                color: #333;
                text-decoration: none;
                border: unset;
                transition: all .2s ease;
                
                &:hover{
                    background: #dadada;
                }

                &:focus{
                    box-shadow: unset;
                }
            }
            
        }
        .active{
            .page-link{
                color: #fff;
                background-color: var(--primary) !important;
            }
        }
    }

`

