import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles



import Hero from './Components/Hero/Hero';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Features from './Components/Features/Features';

function Home() {
    useEffect(() => {
        AOS.init({
            delay: 200,
            duration: 500,
            once: true,
        });
    }, [])
    return (
        <>
            <Hero />
            <About />
            <Services />
            <Features />

        </>
    )
}

export default Home