import { LgStyled } from './Styled.styled'
import { IoIosArrowBack } from 'react-icons/io';

import { sectionList } from 'src/constants/data/services';
import { NavLink } from 'react-router-dom';

function LgScreen() {
    return (
        <LgStyled>
            <div className='container'>
                {sectionList.map((item, i) => (
                    <div data-aos-delay="400" data-aos-duration="600" data-aos={i % 2 ? 'fade-right' : "fade-left"} className={['card', i % 2 ? 'reversed' : null].join(' ')} key={item.key} >
                        <div className="img-con">
                            <img src={item.image} alt={item.title} loading={'lazy'} />
                        </div>
                        <div className='content'>
                            <h4>
                                <span>
                                    {i + 1}.
                                </span>
                                {item.title}
                            </h4>
                            <p>
                                {item.overview}
                            </p>
                            <div className='more-link'>
                                <NavLink to={item.link}>
                                    إقرأ المزيد
                                    <IoIosArrowBack />

                                </NavLink>

                            </div>

                        </div>
                    </div>
                ))}
            </div>

        </LgStyled >
    )
}

export default LgScreen