import { styled } from 'styled-components';


export default styled.article`
    padding: 6rem 0;

    .main-content {
        margin-bottom: 4rem;     
    
    .title{
        font-family: var(--ftl);
        font-weight: bold;
        font-size: 3rem;
        line-height: 3.5rem;
        letter-spacing: 0.02em;
        text-align: center;
        color: #222222;
        word-spacing: -.2rem;
    }

    .text{
        margin-top: 1.25rem;
        font-family: var(--ftl);
        font-style: normal;
        font-weight: 300;
        font-size: 1.1rem;
        line-height: 2rem;
        text-align: center;
        letter-spacing: 0.02em;
        color: #7F7F7F;
    }   

    .underline{
        margin: auto;
        width: 80px;
        height: 5px;
        background: #003399;
        border-radius: 0px 10px;
        margin-top: 2rem;
        }   
    }
    
    .item{
        text-align: center;
        
        .icon{
            color: #fff;
            display: inline-block;
            padding: 1.5rem;
            border-radius: 50%;
            background: #003399;
        }

        .title h4{
            text-align: center;
            font-family: var(--ftl);
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 2.25rem;
            margin-top: 1.5rem;
            letter-spacing: 0.02em;
            color: #222222;
            margin-bottom: .75rem;
        }

        .text{
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1.1rem;
            line-height: 1.8rem;
            text-align: center;
            letter-spacing: 0.03em;
            color: #7F7F7F;
            padding: 0 1.25rem;
        }
    }

    ul {
        li{
            margin-top: 1.25rem;
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1.1rem;
            line-height: 2rem;
            letter-spacing: 0.03em;
            color: #7F7F7F;

            &::marker {
                color: var(--primary);
            }
        }
    }

    @media screen and (max-width:768px){

        .main-content{
            h4.title{
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }

        .item{
            margin-bottom: 2.5rem;
            
            .title{
                h4{
                    margin-top: 1.25rem;
                    margin-bottom: .5rem;

                }
            }
        }
    }

    @media screen and (max-width:576px){
        padding: 4rem 1.25rem;
    }

`