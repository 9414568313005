import { styled } from 'styled-components';


export default styled.section`
    height: 100vh;
    overflow: hidden;

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top center;
        animation: hero 8s ease-in-out .5s infinite alternate forwards;
    }

        @keyframes hero {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.2);
        }
    }

    @media (max-width: 992px){

        height: 420px;
    
    }

    @media (max-width: 768px){
        height: 320px;
    }


`