import { styled } from 'styled-components';


export default styled.section`

    background-color: #F8F8FE;
    padding: 6rem 0;
    
    .title {
        text-align: center;
        margin-bottom: 5rem;

        h4 {
            font-family: var(--ftl);
            font-weight: bold;
            font-size: 3.5rem;
            line-height: 3rem;
            letter-spacing: 0.02em;
            color: #222222;
            word-spacing: -.2rem;
        }
     
        .underline{
            margin: auto;
            margin-top: 1.5rem;
            width: 70px;
            height: 5px;
            background: var(--primary);
            border-radius: 0px 5px;
        }
 
  
    }

@media (max-width: 991px){
    padding: 4rem 0;

    .title h4{
        font-size: 3rem;
    }
}

// @media (max-width: 768px){

// }

@media (max-width: 576px){
    padding: 4rem 1rem;
}








`