import { useParams } from 'react-router-dom';
import Styled from './Styled.styled';

import { useQuery } from '@tanstack/react-query';

import { BiUser } from 'react-icons/bi';
import {
    IoRibbonOutline, IoTimeOutline,
    IoCalendarOutline, IoArrowDownCircleOutline,
    IoAlertCircleSharp
} from 'react-icons/io5';
import { BsCashCoin } from 'react-icons/bs';


import { Spinner, Error as MyError } from 'src/Components';
import BASE_URL, { levels, levelsType } from 'src/constants/vars';


function Detail() {
    const params = useParams();

    const featchCourse = async () => {
        const res = await fetch(`${BASE_URL}/courses/${params.id}`);
        if (res.status === 404) {
            throw new Error('لم يتم العثور على الكورس')
        }
        const jsonData = await res.json();
        return jsonData
    }



    const { data, error, isLoading, isError, isFetching, status } = useQuery({ queryKey: ['courseRetrieveData'], queryFn: () => featchCourse() })

    if (isError) {
        let msg: string = "حدث خطأ ما"
        if (error instanceof Error) {
            msg = error.message;
        }
        return <MyError msg={msg} icon={IoAlertCircleSharp} iconColor={'#1c8eb1'} />
    }
    return (
        <Styled>
            <div className='container'>
                <div className='row'>
                    {isLoading || isFetching ?
                        <Spinner width={80} height={80} />
                        :
                        data !== undefined ?

                            <>
                                <div className='col-12 col-lg-8'>
                                    <div className='post-detail'>
                                        <div className='img-con'>
                                            <img src={data.image} alt={data.name}
                                                height={400} width={"100%"} />
                                        </div>
                                        <div className='content'>

                                            <div className='cats'>
                                                {data.categories.map((item: any) => (
                                                    <div key={`cat-${item.id}`}>
                                                        {item.title}
                                                    </div>
                                                ))}


                                            </div>
                                            <div className='title'>
                                                <h4>
                                                    {data.name}
                                                </h4>
                                            </div>
                                            <div className='ins'>
                                                <div className='icon'>
                                                    <BiUser size={20} />
                                                </div>
                                                المدرب \
                                                {data.instructor}
                                            </div>

                                            <div className="text" dangerouslySetInnerHTML={{ __html: data.content }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-4'>

                                    <div className='row'>
                                        <div className='col-lg-12 col-md-6'>
                                            <div className='my-card'>
                                                <div className='title'>
                                                    <h4>
                                                        نبذ عن الدورة
                                                    </h4>
                                                </div>
                                                <div className='info'>
                                                    <div>
                                                        <div className='icon'>
                                                            <IoRibbonOutline size={20} />
                                                        </div>
                                                        <div className='text'>
                                                            {levels[data.level as keyof levelsType]}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='icon'>
                                                            <IoTimeOutline size={20} />
                                                        </div>
                                                        <div className='text'>
                                                            {data.duration}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='icon'>
                                                            <IoCalendarOutline size={20} />
                                                        </div>
                                                        <div className='text'>
                                                            {data.start_date} {data.end_date ? <span className='t-primary'> - </span> : ''} {data.end_date}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='icon'>
                                                            <BsCashCoin size={20} />
                                                        </div>
                                                        <div className='text price'>
                                                            {data.price}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='btn-con'>
                                                    <a href={data.link}>
                                                        تسجيل
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-6'>
                                            <div className='my-card mt-lg-4'>
                                                <div className='title'>
                                                    <h4>
                                                        ملفات مرفقة
                                                    </h4>
                                                </div>
                                                <div className='files'>

                                                    {data.files.map((item: any) => (
                                                        <a href={item.file} key={`file-${item.id}`}>
                                                            <div className='text'>
                                                                {item.name}
                                                            </div>
                                                            <div className='icon'>
                                                                <IoArrowDownCircleOutline size={25} />
                                                            </div>
                                                        </a>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </>
                            : null

                    }
                </div>
            </div>
        </Styled>
    )
}

export default Detail