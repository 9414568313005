import { styled } from 'styled-components';


export default styled.section`
    padding: 6rem 0;
    
    .title {
        text-align: center;
        
        h4 {
            font-family: var(--ftl);
            font-weight: bold;
            font-size: 3.5rem;
            line-height: 3rem;
            letter-spacing: 0.02em;
            color: #222222;
            word-spacing: -.2rem;
        }

        .underline{
            margin: auto;
            margin-top: 1.5rem;
            width: 70px;
            height: 5px;
            background: var(--primary);
            border-radius: 0px 5px;
        }

    }

    .f-card{
        min-height: 430px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 2.1rem;
        gap: 1rem;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px; 


        .icon{
            color: var(--primary);
        }
        
        .title {
            font-family: var(--ftl);
            font-weight: bold;
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: 0.02em;
            word-spacing: -.2rem;
            color: #222222;
        }

        .text{
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1.1rem;
            line-height: 2rem;
            text-align: center;
            letter-spacing: 0.02em;
            color: #7F7F7F;
        }
    }

    @media (max-width: 991px){
        .title h4{
            font-size: 3rem;
        }
    }

    @media screen and (max-width:576px) {
        .title {

            .underline{
                margin-top: 1rem;
            }
         }
        
         .f-card{
            min-height: 290px;
            box-sizing: content-box;
            margin: 0 1.5rem;
         }
        
    }

`
