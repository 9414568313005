import { styled } from 'styled-components';


export const Styled = styled.article`
    padding: 6rem 0;


    .img-con{
            position: relative;
            margin: auto;
            height:400px;
            overflow: hidden;

            img{
                border-radius: 0px 50px;
                width: 100%;
                height: 100%;
            }
    }

    .content{

        .sm-title{
            font-family: var(--ftl);
            font-style: normal;
            font-weight: 300;
            font-size: 1.35rem;
            line-height: 1rem;
            text-align: right;
            letter-spacing: 0.025em;
            color: #a0a0a0;
        }

        .title {
            margin-bottom: 2rem;

            h4 {
                font-family: var(--ftl);
                font-weight: bold;
                font-size: 3rem;
                line-height: 4rem;
                letter-spacing: 0.02em;
                color: #222222;
                word-spacing: -.2rem;
            }

            .underline{
                margin-top: .5rem;
                width: 80px;
                height: 5px;
                background: #003399;
                border-radius: 0px 5px;
            }
        }

        .sub-title {
            h4 {
                margin-bottom: .75rem;
                font-family: var(--ftl);
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 2rem;
                letter-spacing: 0.025em;
                color: #414141;
                word-spacing: -.1rem;
            }

            .underline{
                margin-top: .6rem;
                width: 80px;
                height: 5px;
                background: #003399;
                border-radius: 0px 10px;
            }
        }

        .text{
            p,
            li{
                font-family: var(--ftl);
                font-style: normal;
                font-weight: 300;
                font-size: 1.1rem;
                line-height: 2.1rem;
                text-align: right;
                letter-spacing: 0.02em;
                color: #7F7F7F;
                margin-bottom: 1rem;
            }

            li{
                margin-bottom: 0.25rem;
                
                &:last-child{
                    margin-bottom: 2rem;
                }
                &::marker{
                    color: #4f5ead;
                }
            }

            img{
                height: fit-content;
                width: 100%;
            }
        }
    }

    .pr{
        color: var(--primary) !important;
        margin-bottom: .5rem !important;
    }

    .bk{
        color: #414141 !important;
        letter-spacing: .02rem;
        margin-bottom: .5rem !important;
        line-height: 1.5rem !important;
        margin-top: 0;
    }


    .f1{ font-size: 1rem !important;}
    .f1-5{ font-size: 1.5rem !important;}
    .f2{ font-size: 2rem !important;}
    .f2-5{ font-size: 2.5rem !important;}
    .f3{ font-size: 3rem !important;}


    @media screen and (max-width:991px){
        .row > div:nth-child(1){
        order: 2 !important;
        }

        .row > div:nth-child(2){
            order: 1 !important;
        }
        .row > div:nth-child(3){
            order: 3 !important;
        }
        .row > div:nth-child(4){
            order: 4 !important;
        }

        .img-con{
            margin-bottom: 3.5rem;
        }

    }

    @media screen and (max-width:576px){
        padding: 4rem 0;

        .img-con{
            margin-bottom: 2.25rem;
            padding-left: .5rem;
            padding-right: .5rem;
            height: 250px;

            img{
                border-radius: 0 30px;
            }
        }

        .content {
            padding-left: 1.1rem;
            padding-right: 1.1rem;
            
            .sm-title{
                line-height: 2rem;
            }

            .title{
                margin-bottom: 1.75rem;

                h4{
                    font-size: 2rem;
                    line-height: 2.5rem;
                }

                .underline{
                    margin-top: .75rem;
                    width: 75px;
                    height: 4px;
                }
            }

            .sub-title{
                h4{
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }

            .text{
                p{
                    margin-bottom: 2rem;
                }
            }
            
        }
    }

`


