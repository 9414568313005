import React from 'react'

import scecImg from 'src/assets/images/services/scec.jpg';
import { Styled } from './Styled.styled';


// studies Consultations Establishing Companies
function SCEC() {
    return (
        <Styled>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-7 content'>
                        <div className='sm-title'>
                            من خدماتنا /
                        </div>
                        <div className='title'>
                            <h4>
                                إعداد الدراسات وتقديم الاستشارات وتأسيس الشركات
                            </h4>
                            <div className='underline'>

                            </div>
                        </div>

                        <div>
                            <div className='text'>
                                <p>
                                    يقـدم المكتـب لعملائه الاستشارات التي تسـاعده في اتخاذ القـرار المناسـب عنـد تأسـيس الشركات مـن حـيث ‏الشـكل القانوني وتحديد رأس المـال المطلوب واختيار أفضل الأشكال القانونية لتأسيس الشركات للحصول على أفضل المزايا القانونية والـضريبية، ويقـوم المكتب بإنهاء كافة إجراءات تأسيس وإشهار الشركات في أوقات قياسية عن طريق وكلائه المتخصصين نظراً لأهمية الانتهاء مـن تلك الإجراءات في أسرع وقت ممكن حتى لا نضيع أي عروض تجارية أو تعطيل لمـصالح المستثمرين.
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    ويقـوم المكتب بتقديم خدمات دراسات الجدوى والاستشارات وتأسيس الشركات بواسطة نخبة ممتازة من المتخصصين يمكنها القيام بالأعمال التالية:
                                </p>

                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className='img-con'>
                            <img src={scecImg} />
                        </div>
                    </div>
                    <div className='col-12 content' style={{ paddingTop: ".5rem" }}>
                        <div className='text'>
                            <ul>
                                <li>
                                    لدى المكتب وكلاء متخصصين في تأسيس كافة أنواع شركات الأموال، (ذات المسؤولية المحدودة، الشركات المساهمة، وغيرها) وشركات الأشخاص والمنشاة الفردية وفروع الشركات الأجنبية ومكاتب التمثيل ومكاتب الاتصال والمكاتب العمالية
                                </li>

                                <li>
                                    إعداد دراسات الجدوى الاقتصادية وهيكلة رأس المال وتحديد حجم التمويل المطلوب
                                </li>
                                <li>
                                    تقديم الاستشارات الاقتصادية والمالية والمحاسبية في مجال أنشطة الشركات وكافة الاعمال المرتبطة بها
                                </li>

                                <li>
                                    إعادة هيكلة الشركات وتغيير الشكل القانوني للشركات كالاندماج أو التقسيم والتصفية.
                                </li>
                                <li>
                                    قيد وتسجيل الشركات في (السجل التجاري، السجل الصناعي، هيئة سوق المال، والبورصة، الوكلاء التجاريين، سجل المصدرين والمستوردين "الرمز الاحصائي"، وغرف التجارة والصناعة)
                                </li>
                                <li>
                                    دراسة وتعديل النظام الأساسي للشركات، اعداد العقود والاتفاقيات الجانبية، وتنظيم انعقاد الجمعيات العامة للمساهمين، ومجالس الإدارة، ومسك سجلات المساهمين، ودفاتر الملكية والتنازلات.
                                </li>
                                <li>
                                    الحصول على التراخيص اللازمة للمشروعات والشركات والمعاونة في حل مشاكلها المالية والمحاسبية والضريبية مع الجهات الحكومية وغير الحكومية.
                                </li>
                            </ul>
                        </div>
                        <div className='title'>
                            <h4 className='mt-5 f2-5'>
                                أنواع الشركات التجارية
                            </h4>
                        </div>

                        <div className='sub-title'>
                            <h4>
                                أولاً: شركات الاشخاص
                            </h4>
                        </div>
                        <div className='text'>
                            <p style={{ marginBottom: '.75rem' }}>
                                وتسمى هذه الشركات بشركات الأشخاص لأنها تقوم أساساً وفي المقام الأول على الاعتبار الشخصي، فهي تنشئ عادة بين عدد قليل من الأشخاص تجمعهم رابطة القرابة أو الصداقة ويضع كل منهم ثقته للآخر بحيث تكون شخصية كل شريك محل اعتبار الشركاء الآخرين، ونظراً لأهمية الاعتبار الشخصي في هذا النوع من الشركات فإن الشركة في الأصل تنحل بوفاة أحد الشركاء أو بفقد أهليته أو بانسحابه من الشركة كما أنه لا يجوز لأحد الشركاء أن يتنازل عن حصته في الشركة إلا بموافقة جميع الشركاء والصورة المثلى لهذا النوع من الشركات هي شركة التضامن وهي تتكون من شركاء متضامنين بمعنى أن الشركاء جميعا يسألون مسئولية شخصية وتضامنية عن كل ديون الشركة في ذمتهم الخاصة فلا تقتصر مسئوليتهم على الحصص المقدمة للشركة وتقترب هذه الشركات كثيرا من الشركات المدنية التي تقوم هي الأخرى على الاعتبار الشخصي وإن كانت تبعد عنها من حيث المسئولية التضامنية للشركاء، ويدخل أيضا تحت هذا النوع شركات التوصية البسيطة، أما النوع الثالث من هذه الشركات فهو شركات المحاصة، وهى عبارة عن شركة خفيه مستتره فلا وجود لها إلا بالنسبة للشركاء ولكن لا وجود لها بالنسبة للغير وعلى هذا لا تتمتع بالشخصية المعنوية ولا يكون لها رأس مال ولا عنوان.
                                <br />
                                وتنقسم هذه الشركات إلى الاقسام التالية:

                                <ul>
                                    <li>
                                        شركات التضامن.
                                    </li>

                                    <li>
                                        شركات التوصية البسيطة.
                                    </li>
                                    <li>
                                        شركات المحاصة.
                                    </li>

                                </ul>
                            </p>

                        </div>
                        <div className='sub-title'>
                            <h4>
                                ثانياً: شركات الاموال
                            </h4>
                        </div>
                        <div className='text'>
                            <p style={{ marginBottom: '.75rem' }}>

                                وتنقسم هذه الشركات إلى الاقسام التالية:
                                <ul>
                                    <li>
                                        الشركات المساهمة.
                                    </li>

                                    <li>
                                        شركات التوصية بالأسهم.
                                    </li>
                                    <li>
                                        الشركات ذات المسؤولية المحدودة.
                                    </li>

                                </ul>
                            </p>

                        </div>
                        <div className='sub-title'>
                            <h4>
                                الشركات المساهمة
                            </h4>
                        </div>
                        <div className='text'>
                            <p>
                                هذه الشركات لا تعتمد على الاعتبار الشخصي ولا يكون له دور في هذه الشركات ولكنها تقوم أساسا على جمع رؤوس الأموال اللازمة لاستغلال نشاط الشركة، وعلى هذا فإن الاعتبار المالي يتقدم ليحتل المركز الأول في هذه الشركات، والنموذج الواضح لهذه الشركات هو الشركة المساهمة، إذا الغالب أن تقوم شركات الأموال بمشروعات ضخمه تحتاج إلى رؤوس أموال كبيرة يعجز عن تقديمها عدد محدود من الشركاء كما هو الحال في شركات الأشخاص وعلى هذا تعتمد هذه الشركات أساسا على جمهور المكتتبين حيث يقسم رأس مال الشركة إلى أسهم متساوية القيمة وتكون عادة قيمة السهم زهيدة حتى يتمكن عدد كبير من ذوى الدخل المحدود من الاكتتاب فيها، وتقتصر مسئولية المساهم في هذه الشركة على قيمة الأسهم التي اكتتب فيها.
                            </p>

                        </div>
                        <div className='sub-title'>
                            <h4>
                                شركة التوصية بالأسهم
                            </h4>
                        </div>
                        <div className='text'>
                            <p>
                                تتكون هذه الشركات من نوعين من الشركاء شركاء موصون لا يسألون إلا بمقدار الحصص التي يقدمونها وتتخذ هذه الحصص شكل أسهم قابلة للتداول لا تختلف عن الأسهم في شركات المساهمة، وشركاء متضامنون يسري عليهم ما يسري على الشركاء المتضامنين في شركات التضامن وشركات التوصية البسيطة، ومن ثم يكتسبون صفة التاجر وتكون مسئوليتهم مطلقة في جميع أموالهم ويقومون بإدارة الشركة..
                            </p>

                        </div>

                        <div className='sub-title'>
                            <h4>
                                الشركة ذات المسؤولية المحدودة
                            </h4>
                        </div>
                        <div className='text'>
                            <p>
                                هذه الشركات عادة ما تكون أصغر من الشركات المساهمة، كما عادة ما تكون هناك علاقات شخصية بين أصحاب رأس المال وهي تشبه الشركة ذات المسئولية المحدودة شركات الأشخاص من ناحية أن عدد الشركاء فيها لا يجوز أن يزيد على خمسة وعشرون شريكاً وأن حصة الشريك فيها ليست قابلة للتداول بالطرق التجارية بل يخضع تداولها لقيود معينة أهمها إمكان استرداد هذه الحصة للشركاء وأنه لا يجوز تأسيسها عن طريق الاكتتاب العام وفى النهاية لا يجوز لها إصدار أسهم أو سندات قابلة للتداول، وتقترب هذه الشركة من شركات الأموال من حيث نظام إدارتها وتأسيسها ومن حيث تحديد مسئولية الشركاء فيها وانتقال حصة كل شريك إلى ورثته.
                            </p>
                        </div>

                        <div className='title'>
                            <h4 className='mt-5 f2-5'>
                                المستندات المطلوبة لتأسيس الشركات
                            </h4>
                        </div>
                        <div className='sub-title'>
                            <h4>
                                أولاً: الشركات المساهمة
                            </h4>
                        </div>
                        <div className='text'>
                            <p className='pr'>
                                عند التأسيس:
                                <br />
                            </p>
                            <ul>
                                <li>
                                    عقد التأسيس.
                                </li>

                                <li>
                                    النظام الأساسي.
                                </li>
                                <li>
                                    رسالة من أحد المصارف التجارية تفيد دفع 30% من رأس مال الشركة المكتتب.
                                </li>


                                <li>
                                    الإثباتات الشخصية لرئيس وأعضاء مجلس إدارة الشركة.
                                </li>

                                <li>
                                    الإثباتات الشخصية لرئيس وأعضاء هيئة المراقبة + المؤهلات العلمية.
                                </li>
                                <li>
                                    نموذج التوقيع الخطي للممثل القانوني للشركة (رئيس مجلس الإدارة).
                                </li>
                                <li>
                                    ملف معلق.
                                </li>
                            </ul>

                            <p className='pr'>
                                عند التجديد:
                            </p>
                            <ul>
                                <li>
                                    طلب التجديد.
                                </li>

                                <li>
                                    نسخة عن مستخرج السجل التجاري للشركة.
                                </li>
                                <li>
                                    آخر ميزانية معتمدة.
                                </li>
                                <li>
                                    تقرير هيئة المراقبة.
                                </li>
                            </ul>
                            <p className='pr'>
                                عند التعديل:
                            </p>

                            <p className='bk'>
                                1.
                                إعادة تشكيل مجلس إدارة الشركة وهيئة المراقبة:
                            </p>
                            <ul>
                                <li>
                                    إيداع نسخة عن قرار الجمعية العمومية بشأن إعادة التشكيل.
                                </li>

                                <li>
                                    إيداع نسخة عن محضر اجتماع الجمعية العمومية + مرفق توقيعات الحضور.
                                </li>
                                <li>
                                    إيداع نسخة عن صحيفة توحيد إعلان الدعوة للاعتماد.
                                </li>
                                <li>
                                    إيداع نسخ عن تفويضات الحضور حال الإنابة.
                                </li>
                                <li>
                                    إيداع الإثباتات الشخصية للعميل الجديد + المؤهلات العلمية لأعضاء هيئة المراقبة.
                                </li>
                            </ul>



                            <p className='bk'>
                                2.
                                زيادة رأس مال الشركة:
                            </p>
                            <ul>
                                <li>
                                    إيداع نسخة عن محضر اجتماع الجمعية العمومية غير العادية الذي تقرر بموجبه زيادة رأس مال الشركة + صحيفة إعلان الدعوة.
                                </li>

                                <li>
                                    إيداع آخر ثلاثة ميزانيات معتمدة للسنوات السابقة لإقرار الزيادة.
                                </li>
                                <li>
                                    وفى حالة الزيادة تعتمد عينية.
                                </li>
                                <li>
                                    يضاف تقرير الخبير القضائي المنتدب من المحكمة المختصة.
                                </li>
                            </ul>

                            <p className='bk'>
                                3.
                                خفض رأس المال:
                            </p>
                            <ul>
                                <li>
                                    نفس السابق + تقرير هيئة المراقبة.
                                </li>
                            </ul>

                            <p className='bk'>
                                4.
                                انسحاب وانضمام الأعضاء (المساهمين):
                            </p>
                            <ul>
                                <li>
                                    إيداع إقرارات الانسحاب والانضمام.
                                </li>

                                <li>
                                    محضر اجتماع الجمعية العمومية غير العادية بالخصوص.
                                </li>
                                <li>
                                    نسخة عن تعديل عقد التأسيس.
                                </li>
                            </ul>
                        </div>

                        {/* ******************************************** */}
                        <div className='sub-title'>
                            <h4>
                                ثانياً: فروع الشركات الأجنبية:
                            </h4>
                        </div>

                        {/* ****************************************************** */}


                        <div className='text'>
                            <p className='pr'>
                                عند التأسيس:
                            </p>
                            <ul>
                                <li>
                                    قرار الوزير المختص بمنح الإذن بفتح فرع الشركة فى ليبيا + رسالة إدارة الشركات والتسجيلات التجارية بوزارة الاقتصاد والصناعة.
                                </li>

                                <li>
                                    نسخة عن عقد تأسيس الشركة ونظامها الأساسي معتمد من المكتب الشعبي بدولة المقر أو من يقوم مقامه وترجمته داخل احد مكاتب الترجمة القانونية بليبيا.
                                </li>
                                <li>
                                    نسخة عن قرار الشركة الأم بفتح الفرع يضمن رأس المال + مدير الفرع ونائبه.
                                </li>


                                <li>
                                    رسالة تعتمد بتحويل المبلغ الواجب تحويله.
                                </li>

                                <li>
                                    نموذج التوقيع الخطي لمدير الفرع.
                                </li>

                            </ul>

                            <p className='pr'>
                                عند التجديد:
                            </p>
                            <ul>
                                <li>
                                    نسخة عن آخر مستخرج للسجل التجاري.
                                </li>

                                <li>
                                    آخر ميزانية معتمدة للفرع.
                                </li>
                                <li>
                                    كشف بالعمالة الوطنية.
                                </li>
                            </ul>
                            <p className='pr'>
                                عند التعديل:
                            </p>

                            <p className='bk'>
                                1.
                                إعادة تشكيل مجلس إدارة الشركة وهيئة المراقبة:
                            </p>
                            <ul>
                                <li>
                                    تعديل المدير أو نائبه.
                                </li>

                                <li>
                                    قرار مجلس إدارة الشركة الأم معتمد ومصدق عليه من المكتب الشعبي بدولة المقر + رسالة إدارة الشركات
                                </li>
                                <li>
                                    الاثبات الشخصي لمدير الفرع + نموذج توقيعه الخطي.
                                </li>
                                <li>
                                    إيداع نسخ عن تفويضات الحضور حال الإنابة.
                                </li>
                                <li>
                                    نسخة عن أخر مستخرج.
                                </li>
                            </ul>
                            <p className='bk'>
                                عند حل وشطب الفرع:
                            </p>
                            <ul>
                                <li>
                                    قرار الوزير المختص بإلغاء الإذن الممنوح للفرع.
                                </li>

                                <li>
                                    قرار مجلس إدارة الشركة الأم بحل الفرع.
                                </li>
                                <li>
                                    مستخرج السجل التجاري.
                                </li>
                                <li>
                                    ما يفيد الإعلان عن حل الفرع.
                                </li>
                                <li>
                                    ميزانية العمومية تتضمن المركز المالي والا فصاحات المالية المكملة معتمدة من مراجع حسابات خارجي ومصدق عليها من الشركة الأم.
                                </li>
                                <li>
                                    إفادة من المصفى القانوني بالانتهاء من أعمال التصفية.
                                </li>
                                <li>
                                    إخطار بالدفع صادر عن قسم التفتيش بصندوق الضمان الاجتماعي بسداد الاشتراكات الضمانية المترتبة على الفرع.
                                </li>
                                <li>
                                    أصل شهادة السداد الضريبي.
                                </li>
                            </ul>
                        </div>


                        {/* ****************************************************** */}

                        <div className='sub-title'>
                            <h4>
                                ثالثاً: الشركات المشتركة
                            </h4>
                        </div>

                        {/* ****************************************************** */}

                        <div className='text'>
                            <p className='pr'>
                                عند التأسيس:
                            </p>
                            <ul>
                                <li>
                                    قرار من مجلس إدارة الشركة الوطنية والأجنبية بالموافقة على المساهمة في تأسيس شركة مشتركة وقيمة مساهمتها فيها.
                                </li>


                                <li>
                                    نسخة من عقد التأسيس والنظام الأساسي للشركة الوطنية والأجنبية.
                                </li>

                                <li>
                                    مستخرج حديث من قيد الشركة الوطنية والأجنبية في السجل التجاري أو ما يقوم مقامه.
                                </li>


                                <li>
                                    إفادة صادرة من أحد المصارف العاملة في ليبيا يبين فيها تمام دفع ما يتوجب دفعه من رأس مال الشركة النقدي، بحيث لا يقل عن ثلاثة أعشار رأس المال النقدي وموضحاً فيها قيام الشريك الأجنبي بتحويل الجزء الواجب عليه أداؤه من حصته النقدية في رأس مال الشركة إلى ليبيا .
                                </li>



                                <li>
                                    ما يفيد تقديم وتقويم الحصة العينية .
                                </li>

                                <li>
                                    ويجب أن تكون المستندات المشار إليها في البند (1-2-3) مصدقاً عليها من الدوائر الرسمية المختصة في دولة المقر بالنسبة للشريك الأجنبي، ومن السفارة الليبية أو ما يقوم مقامها في تلك الدولة على أن تتم الترجمة القانونية للمستندات إلى اللغة العربية داخل ليبيا.
                                </li>
                            </ul>

                            <p className='pr'>
                                عند التجديد:
                            </p>
                            <ul>
                                <li>
                                    طلب كتابي + مستخرج السجل التجاري.
                                </li>

                                <li>
                                    ( تعديل – حل – تصفية – شطب ): تتخذ الشركات المشتركة شكل الشركة المساهمة وفقاً لقانون النشاط التجاري رقم 23 لسنة 2010م.
                                </li>

                            </ul>
                            <p className='pr'>
                                عند التعديل:
                            </p>

                            <p className='bk'>
                                1.
                                إعادة تشكيل مجلس إدارة الشركة وهيئة المراقبة:
                            </p>
                            <ul>
                                <li>
                                    تعديل المدير أو نائبه.
                                </li>

                                <li>
                                    قرار مجلس إدارة الشركة الأم معتمد ومصدق عليه من المكتب الشعبي بدولة المقر + رسالة إدارة الشركات
                                </li>
                                <li>
                                    الاثبات الشخصي لمدير الفرع + نموذج توقيعه الخطي.
                                </li>
                                <li>
                                    إيداع نسخ عن تفويضات الحضور حال الإنابة.
                                </li>
                                <li>
                                    نسخة عن أخر مستخرج.
                                </li>
                            </ul>
                            <p className='bk'>
                                عند حل وشطب الفرع:
                            </p>
                            <ul>
                                <li>
                                    قرار الوزير المختص بإلغاء الإذن الممنوح للفرع.
                                </li>

                                <li>
                                    قرار مجلس إدارة الشركة الأم بحل الفرع.
                                </li>
                                <li>
                                    مستخرج السجل التجاري.
                                </li>
                                <li>
                                    ما يفيد الإعلان عن حل الفرع.
                                </li>
                                <li>
                                    ميزانية العمومية تتضمن المركز المالي والا فصاحات المالية المكملة معتمدة من مراجع حسابات خارجي ومصدق عليها من الشركة الأم.
                                </li>
                                <li>
                                    إفادة من المصفى القانوني بالانتهاء من أعمال التصفية.
                                </li>
                                <li>
                                    إخطار بالدفع صادر عن قسم التفتيش بصندوق الضمان الاجتماعي بسداد الاشتراكات الضمانية المترتبة على الفرع.
                                </li>
                                <li>
                                    أصل شهادة السداد الضريبي.
                                </li>
                            </ul>
                        </div>


                    </div>

                </div>
            </div>
        </Styled >
    )
}

export default SCEC