import { MdStyled } from './Styled.styled'
import { IoIosArrowBack } from 'react-icons/io';

import { sectionList } from 'src/constants/data/services';
import { NavLink } from 'react-router-dom';

function MdScreen() {
    return (
        <MdStyled>
            <div className='container'>
                <div className='row'>
                    {sectionList.map((item, i) => (
                        <div className='col-12 col-lg-6'>
                            <div className="card" key={item.key} >
                                <div className="img-con">
                                    <img src={item.image} alt={item.title} loading={'lazy'} />
                                </div>
                                <div className='content'>
                                    <h4>
                                        <span>
                                            {i + 1}.
                                        </span>
                                        {item.title}
                                    </h4>
                                    <p>
                                        {item.overview}
                                    </p>
                                    <div className='more-link'>
                                        <NavLink to={item.link}>
                                            إقرأ المزيد
                                            <IoIosArrowBack />
                                        </NavLink>

                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

        </MdStyled >
    )
}

export default MdScreen