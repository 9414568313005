import './Nav.css';

import Logo from 'src/assets/svg/logo.svg';


import { NavLink } from 'react-router-dom';




import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';







type linkType = {
    id: string,
    to?: string,
    name: string,
    menu?: linkType[]
}

type linksType = linkType[]

const links: linksType = [
    {
        id: "link-1",
        to: "/",
        name: "الرئيسية",
    },
    {
        id: "link-4",
        name: "خدماتنا",

        menu: [
            { id: "sub-link-1", to: "serivces/accounting-and-systems-design-services", name: "خدمات المحاسبة وتصميم النظم", menu: [] },
            { id: "sub-link-2", to: "serivces/review-and-evaluation-of-companies", name: "مراجعة وتقييم الشركات", menu: [] },
            { id: "sub-link-3", to: "serivces/studies-consultations-establishing-companies", name: "إعداد الدراسات وتقديم الاستشارات وتأسيس الشركات", menu: [] },
            { id: "sub-link-4", to: "serivces/feasibility-studies-and-estimated-budgets", name: "دراسات الجدوى والموازنات التقديرية" },
            { id: "sub-link-5", to: "serivces/customer-relationship-management", name: "إدارة علاقات العملاء" }
        ]
    },
    {
        id: "link-3",
        to: "/courses",
        name: "الدورات التدريبية",
    }
    ,
    {
        id: "link-2",
        to: "/about",
        name: "من نحن",
    }

]

export default () => {
    const expand = 'md';

    return (<Navbar className='my-nav' expand={expand}>
        <Container>
            <Navbar.Brand href="#">
                <img height={33} width={178} src={Logo} alt="logo" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                        <img src={Logo} alt="logo" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        {links.map(item => (
                            item.menu === undefined || item.menu.length < 1 ?
                                <Nav.Link key={item.id} as={"div"} >
                                    <NavLink to={item.to || '/'}>
                                        {item.name}
                                    </NavLink>
                                </Nav.Link>
                                :
                                <NavDropdown
                                    title={item.name}
                                    key={item.id}
                                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                                >
                                    {item?.menu.map(i => (

                                        <NavDropdown.Item key={item.id} as={"div"}>
                                            <NavLink to={i.to || '/'}>
                                                {i.name}
                                            </NavLink>
                                        </NavDropdown.Item>
                                    ))}

                                </NavDropdown>
                        ))}


                    </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
    </Navbar>);
}




